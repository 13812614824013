.hero_wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

.hero_content .section_title {
  font-size: 3rem;
  padding-right: 60px;
  line-height: 50px;
  margin-bottom: 20px;
}

.highlights {
  color: var(--primary-color);
}

.hero_content p {
  font-size: 1.1rem;
  color: var(--heading-color);
  margin-bottom: 50px;
  line-height: 30px;
}

.hero_btns {
  display: flex;
  align-items: center;
  gap: 2.7rem;
  /* Changed column-gap to gap for broader browser support */
}

.box-01 {
  width: 400px;
  height: 400px;
  border-radius: 100%;
  border: 25px solid rgba(141, 141, 235, 1);
}

.box-02 {
  width: 350px;
  height: 350px;
  border-radius: 100%;
  border: 25px solid #b5a9f3;
}

.box-03 {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  border: 25px solid #b5a9f3;
}

.hero_img-wrapper {
  position: relative;
  z-index: 1;
}

.box_img {
  position: absolute;
  top: 7%;
  left: -13%;
  z-index: 10;
}

.box_img img {
  width: 123%; /* Adjusted width for better fit */
}

.heart_rate {
  position: absolute;
  top: 55%;
  left: -11%;
  z-index: 10;
  box-shadow: -3px 3px -1px #d3cef2;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px;
}

.heart_rate span {
  width: 30px;
  height: 25px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d3cef2;
  color: #d7337a;
  border-radius: 3px;
  padding: 3px;
}

.heart_rate h5,
.heart_rate h6 {
  font-size: 0.8rem;
  color: var(--heading-color);
  font-weight: 600;
}

.heart_rate h5 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.heart_rate h6 {
  margin-bottom: 10px;
}

.gym_location {
  position: absolute;
  top: -2%;
  right: -17%;
  z-index: 10;
  box-shadow: -3px 3px 8px -1px #d3cef2;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 1em;
}

.gym_location span {
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--primary-color);
  color: #fff;
  font-size: 1.5rem;
  border-radius: 50%;
}

.gym_location h5 {
  color: var(--heading-color);
  margin-top: 1rem;
  font-size: 0.9rem;
}

.dumble_icon {
  position: absolute;
  top: -2%;
  left: -5%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #d7337a;
  color: #fff;
  border-radius: 5px;
}

.dumble_icon img {
  width: 100%;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  background: #f3f4f6;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url('../../assets/Img/gym-02.png') no-repeat center/cover; */
  opacity: 0.1;
  z-index: 1;
}

.hero_content {
  text-align: center;
  position: relative;
  z-index: 2;
  animation: fadeInUp 1s ease-out;
}

.section_title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.highlights {
  color: var(--primary-color);
}

.hero_content p {
  font-size: 1rem;
  color: var(--heading-color);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.hero_btn .register_btn {
  border: none;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hero_btn .register_btn:hover {
  background-color: black;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .hero_wrapper {
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
  }

  .hero_content {
    text-align: center;
    padding: 0 20px;
  }

  .hero_content .section_title {
    font-size: 2rem;
    margin-left: 5.5rem;
  }

  .hero_content p {
    font-size: 0.9rem;
  }

  .hero_btn .register_btn {
    padding: 8px 16px;
    margin-bottom: 2rem;
  }
}
