.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
}

.nav_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.logo_img {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_img img {
  width: 100%;
}

.logo h2 {
  color: var(--heading-color);
  font-weight: 700;
}

.menu {
  display: flex;
  align-items: center;
  column-gap: 2.7rem;
}

.nav_item a {
  color: var(--heading-color);
  font-weight: 600 !important;
  cursor: pointer;
  transition: 0.3s;
  font-size: 1.1rem;
}
.nav_item a:hover {
  color: var(--primary-color);
}

.nav_right {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.register_btn {
  border: none;
  outline: none;
  padding: 5px 10px; /* Reduced padding for a more balanced look */
  color: var(--primary-color);
  border-radius: 5px;
  font-family: var(--font-name);
  font-size: 1rem;
  font-weight: 700;
}

.mobile_menu {
  color: var(--heading-color);
  font-size: 1.3rem;
  cursor: pointer;
  display: none;
}

.sticky_header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: #fff;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    align-items: center;
    background: white;
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  .menu.active {
    display: flex;
  }

  .nav_item {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .nav_right {
    display: flex;
    align-items: center;
  }

  .mobile_menu {
    display: block;
  }
}
