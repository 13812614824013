:root {
  --heading-color: #333;
  --bg-gradient: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  --highlight-color: #ff6347;
}

.exercise_top {
  margin-bottom: 50px;
  text-align: center;
}

.exercise_top h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: var(--highlight-color);
}

.exercise_top p {
  color: var(--heading-color);
  font-size: 1rem;
  line-height: 1.75;
  margin: 0 auto;
  max-width: 600px;
}

.exercise_wrapper {
  background: var(--bg-gradient);
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 60px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  justify-content: space-evenly;
}

.exercise_item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  width: 100%;
  max-width: 300px;
  margin-bottom: 2rem;
  padding: 1rem;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.exercise_item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.exercise_icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  background: #fff;
}

.exercise_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exercise_content {
  flex: 1;
}

.exercise_content h4 {
  font-size: 1.3rem;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.exercise_content p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
}
