.start_wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 3rem;
  align-items: center;
}

.start_img,
.start_content {
  width: 50%;
}

.start_img img {
  width: 100%;
}

.start_content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.start_content p {
  color: var(--heading-color);
  font-size: 1.1rem;
  line-height: 28px;
  margin-bottom: 50px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .start_wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Center text for better readability on mobile */
  }

  .start_img,
  .start_content {
    width: 100%; /* Full width for both image and content on mobile */
  }

  .start_content h2 {
    font-size: 2rem;
    margin-top: 3rem;
  }

  .start_content p {
    font-size: 1rem;
    margin-bottom: 30px; /* Reduce margin for better spacing on mobile */
  }

  .start_img img {
    max-width: 100%; /* Ensure the image fits within its container */
  }
}
