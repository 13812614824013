/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

:root {
  --primary-color: #6f55f2;
  --heading-color: #90ee90;
  --bg-gradient: linear-gradient(
    90deg,
    rgba(103, 101, 240, 1) 27%,
    rgba(141, 141, 235, 1) 85%
  );
  --font-name: 'Questrial', sans-serif;

  /* 
        #9785f2
        #b5a9f3
        #d3cef2
        #d7337a
    */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);
}

section {
  padding: 50px 0px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}
ul {
  list-style: none;
}

.container {
  width: 1140px;
  padding: 0px 13px;
  margin: auto;
}
.section-title {
  color: var(--heading-color);
  font-weight: 700;
}
